<template>
  <div>
    <div class="modal-body">
      <div class="form-row">
        <div
          class="form-group col-md-12 required"
          :class="{ 'is-invalid': errors.has('name') }"
        >
          <label for="name_2_field" class="control-label text-capitalize"
            >Name:</label
          >
          <input
            id="name_2_field"
            v-model="store.name"
            v-validate="{ required: true }"
            type="text"
            name="name"
            class="form-control"
          />
          <span v-show="errors.has('name')" class="invalid-feedback">{{
            errors.first("name")
          }}</span>
        </div>
        <div
          class="form-group col-md-12"
          :class="{ 'is-invalid': errors.has('telephone') }"
        >
          <label for="telephone" class="control-label text-capitalize"
            >Telephone:</label
          >
          <TelephoneInput
            @valueChange="setTelephoneValue" 
            :telephone="store.telephone"
            id="telephone"
            name="telephone"
          />
          <span v-show="errors.has('telephone')" class="invalid-feedback">{{
            errors.first("telephone")
          }}</span>
        </div>
        <div
          class="form-group col-md-12"
          :class="{ 'is-invalid': errors.has('title') }"
        >
          <label for="title_2_field" class="control-label text-capitalize"
            >Opening hours:</label
          >
          <textarea
            id="title_2_field"
            v-model="store.opening_hours"
            type="text"
            name="title"
            class="form-control"
            placeholder=""
          />
          <span v-show="errors.has('title')" class="invalid-feedback">{{
            errors.first("title")
          }}</span>
        </div>
        <div
          class="form-group col-md-12"
          :class="{ 'is-invalid': errors.has('title') }"
        >
          <label for="title_2_field" class="control-label text-capitalize"
            >Information:</label
          >
          <textarea
            id="title_2_field"
            v-model="store.information"
            type="text"
            name="title"
            class="form-control"
            placeholder=""
          />
          <span v-show="errors.has('title')" class="invalid-feedback">{{
            errors.first("title")
          }}</span>
        </div>

        <div class="form-group col-md-12">
          <div class="rounded py-3 px-5 border">
            <editable-address
              :simple="true"
              :address="store.address"
              :default-country="getDefaultCountry"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <ButtonLoading
        :loading="loading"
        :title="'Save store'"
        :class="'btn btn-secondary float-right'"
        v-on:click.native="submitForm"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import jsonAPI from "@/api";
import Permission from "@/utils/permission";
import EditableAddress from "@/components/UI/EditableAddress.vue";
import ButtonLoading from "./ButtonLoading.vue";
import TelephoneInput from "@/components/Form/TelephoneInput.vue";

export default {
  name: "StoreForm",
  components: {
    EditableAddress,
    ButtonLoading,
    TelephoneInput,
  },
  extends: Permission,
  props: {
    store: {
      type: Object,
      default: () => {
        return {
          id: null,
          name: null,
          telephone: null,
          information: "",
          opening_hours: "",
        };
      },
    },
  },
  computed: {
    ...mapGetters(["getOrganization"]),
    getDefaultCountry() {
      console.log(this.getOrganization);
      return (
        this.getOrganization.country_obj ?? {
          name: "",
          code: "",
        }
      );
    },
  },
  data() {
    return {
      loadingDelete: false,
      loading: false,
    };
  },
  methods: {
    async submitDeleteForm() {
      this.loadingDelete = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          jsonAPI
            .delete("store/" + this.store.id, this.store)
            .then(() => {
              window.Bus.$emit("flash-message", {
                text: "Store deleted",
                type: "success",
              });
              this.loadingDelete = false;
              this.closeModal();
            })
            .catch((error) => {
              Object.keys(error.response.data.errors).forEach((key) => {
                this.$validator.errors.add({
                  field: key,
                  msg: error.response.data.errors[key][0],
                });
              });
              this.loadingDelete = false;
              window.Bus.$emit("flash-message", {
                text: "error main",
                type: "error",
              });
            });
        } else {
          this.loadingDelete = false;
        }
      });
    },
    async submitForm() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          jsonAPI
            .put("store/" + this.store.id, this.store)
            .then(() => {
              window.Bus.$emit("flash-message", {
                text: "Store updated",
                type: "success",
              });
              this.loading = false;
              this.closeModal();
            })
            .catch((error) => {
              Object.keys(error.response.data.errors).forEach((key) => {
                this.$validator.errors.add({
                  field: key,
                  msg: error.response.data.errors[key][0],
                });
              });
              this.loading = false;
              window.Bus.$emit("flash-message", {
                text: "error main",
                type: "error",
              });
            });
        } else {
          this.loading = false;
        }
      });
    },
    closeModal() {
      this.$emit("close");
    },
    setTelephoneValue(value) {
      this.store.telephone = value;
    }
  },
};
</script>
