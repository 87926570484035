





























import { Component, Prop, Vue } from "vue-property-decorator";
import jsonAPI from "@/api";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";
import Delete from "@/components/Svg/Delete.vue";
import DeleteCircle from "@/components/Svg/DeleteCircle.vue";
import Prompt from "@/components/UI/Prompt.vue";

@Component({
  name: "DeleteIconButton",
  components: {
    ButtonLoading,
    Delete,
    DeleteCircle,
    Prompt,
  },
})
export default class DeleteIconButton extends Vue {
  @Prop() name!: string;
  @Prop() uri!: string;

  isPromptVisible = false;
  loading = false;

  showPrompt() {
    window.scrollTo(0, 0);
    this.isPromptVisible = true;
  }
  close() {
    this.isPromptVisible = false;
  }
  deleteItem() {
    if (this.uri) {
      jsonAPI
        .delete(this.uri)
        .then(() => {
          this.$emit("deleted");
          this.loading = false;
          this.close();
        })
        .catch(() => {
          window.Bus.$emit("flash-message", {
            text: `Error deleting ${this.name}`,
            type: "error",
          });
          this.loading = false;
        });
    } else {
      this.$emit("confirm");
    }
  }
}
