<template>
  <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 512 512">
    <path
      d="M452.923 98.462h-98.462C354.462 44.081 310.38 0 256 0s-98.462 44.081-98.462 98.462H59.077c-13.598 0-24.615 11.018-24.615 24.615s11.018 24.615 24.615 24.615h9.846V448c.059 35.328 28.672 63.941 64 64h246.154c35.328-.059 63.941-28.672 64-64V147.692h9.846c13.598 0 24.615-11.018 24.615-24.615s-11.017-24.615-24.615-24.615zM256 49.231c27.185 0 49.231 22.046 49.231 49.231h-98.462c0-27.186 22.046-49.231 49.231-49.231zM393.846 448c0 8.153-6.617 14.769-14.769 14.769H132.923c-8.153 0-14.769-6.617-14.769-14.769V147.692h275.692V448z"
      fill="#ff485a"
    />
    <g fill="#ffbbc0">
      <path
        d="M201.846 379.077c-13.598 0-24.615-11.018-24.615-24.615V256c0-13.598 11.018-24.615 24.615-24.615s24.615 11.018 24.615 24.615v98.462c.001 13.597-11.017 24.615-24.615 24.615zM310.154 379.077c-13.598 0-24.615-11.018-24.615-24.615V256c0-13.598 11.018-24.615 24.615-24.615 13.598 0 24.615 11.018 24.615 24.615v98.462c0 13.597-11.018 24.615-24.615 24.615z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Delete",
};
</script>

<style scoped></style>
