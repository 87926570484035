<template>
  <div class="card mb-6">
    <div class="card-body">
      <div class="media w-100">
        <div
          class="avatar-initials mr-4"
          :style="[{ background: getRandomColor(index) }]"
        >
          {{ getInitials(store.name) }}
        </div>
        <div class="media-body">
          <div class="row">
            <div class="col">
              <p class="font-weight-bold text-dark mb-0">
                {{ store.name }}
              </p>
              <p class="text-muted mb-0">
                {{ store.information }}
              </p>
            </div>
            <div class="col d-flex flex-column  align-items-end">
              <DeleteIconButton
                v-if="hasPermission('delete-stores')"
                v-tooltip.left="'Remove'"
                class="loat-right cursor-pointer mb-4"
                :uri="`store/${store.id}`"
                :name="store.name"
                @deleted="storeDeleted"
              />
              <button
                v-if="hasPermission('edit-stores')"
                class="btn btn-sm btn-outline-secondary float-right"
                @click="editStore"
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Permission from "@/utils/permission";
import DeleteIconButton from "@/components/Form/DeleteIconButton.vue";

export default {
  name: "StoreCard",
  components: {
    DeleteIconButton,
  },
  extends: Permission,
  props: {
    store: {
      type: Object,
      default: () => {
        return {
          id: "",
          name: "",
          title: "",
        };
      },
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      colors: [
        "#1fbc9c",
        "#2ecc70",
        "#3398db",
        "#a463bf",
        "#3d556e",
        "#f2c511",
        "#e84b3c",
        "#2ecc70",
        "#3398db",
        "#a463bf",
        "#3d556e",
        "#f2c511",
        "#e84b3c",
      ],
    };
  },
  methods: {
    getRandomColor: function(index) {
      return this.colors[index];
    },
    getInitials: function(name) {
      var parts = name.split(" ");
      var initials = "";
      for (var i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== "") {
          initials += parts[i][0];
        }
      }
      return initials.toUpperCase();
    },
    editStore() {
      this.$emit("editStore", this.store.id);
    },
    storeDeleted() {
      this.$emit("deleteStore", this.store.id);
    },
  },
};
</script>

<style scoped></style>
