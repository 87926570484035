<template>
  <div>
    <div class="modal-body">
      <div class="form-row">
        <div
          class="form-group col-md-6 required"
          :class="{ 'is-invalid': errors.has('name') }"
        >
          <label for="name_field" class="control-label text-capitalize"
            >Name:</label
          >
          <input
            id="name_field"
            v-model="formData.name"
            v-validate="{ required: true }"
            type="text"
            name="name"
            class="form-control"
            placeholder="Name"
          />
          <span v-show="errors.has('name')" class="invalid-feedback">{{
            errors.first("name")
          }}</span>
        </div>
        <div
          class="form-group col-md-6"
          :class="{ 'is-invalid': errors.has('title') }"
        >
          <label for="title_field" class="control-label text-capitalize"
            >Title:</label
          >
          <input
            id="title_field"
            v-model="formData.title"
            v-validate="{ required: false }"
            type="text"
            name="title"
            class="form-control"
            placeholder="Analyst"
          />
          <span v-show="errors.has('title')" class="invalid-feedback">{{
            errors.first("title")
          }}</span>
        </div>
        <div
          class="form-group col-md-6 required"
          :class="{ 'is-invalid': errors.has('role') }"
        >
          <label for="role" class="control-label text-capitalize">Role:</label>
          <select
           id="role"
            v-model="formData.role"
            v-validate="{ required: true }"
            name="role"
            class="form-control"
          >
            <option v-for="role in roles" :key="role.id" :value="role.id">
              {{ role.name }}
            </option>
          </select>
          <span v-show="errors.has('role')" class="invalid-feedback">{{
            errors.first("role")
          }}</span>
        </div>
        <div
          class="form-group col-md-6 required"
          :class="{ 'is-invalid': errors.has('email') }"
        >
          <label for="email" class="control-label text-capitalize"
            >Email:</label
          >
          <input
            id="email"
            v-model="formData.email"
            v-validate="{ required: true, email: true }"
            type="email"
            name="email"
            class="form-control"
            placeholder="example@email.com"
          />
          <span v-show="errors.has('email')" class="invalid-feedback">{{
            errors.first("email")
          }}</span>
        </div>
        <div
          class="form-group col-md-6 required"
          :class="{ 'is-invalid': errors.has('store') }"
        >
          <label for="store" class="text-capitalize"
            >Store:</label
          >
          <select
            id="store"
            v-model="formData.store_id"
            v-validate="{ required: false }"
            name="store"
            class="form-control"
          >
            <option :value="''">Select store</option>
            <option v-for="store in stores" :key="store.id" :value="store.id">
              {{ store.name }}
            </option>
          </select>
          <span v-show="errors.has('store')" class="invalid-feedback">{{
            errors.first("store")
          }}</span>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="form-group col-12 mb-0">
        <ButtonLoading
          :loading="loading"
          :title="'Send invites'"
          :class="'btn btn-secondary float-right'"
          v-on:click.native="submitForm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import jsonAPI from "@/api";
import ButtonLoading from "./ButtonLoading.vue";

export default {
  name: "InviteForm",
  components: { 
    ButtonLoading,
  },
  data() {
    return {
      loading: false,
      formData: {
        name: null,
        title: null,
        sender: null,
        role: 3,
        email: null,
        organization_id: null,
        store_id: null,
      },
      roles: [],
      stores: [],
      step_status: {
        key: "invite_teammates",
        status: true,
      },
    };
  },
  computed: {
    ...mapGetters(["getUser", "getOrganization"]),
  },
  mounted() {
    console.log("mounted.");
    jsonAPI.get("organization/roles").then((response) => {
      this.roles = response.data;
    });
    jsonAPI.get("store").then((response) => {
      this.stores = response.data.data;
    });
  },
  methods: {
    async submitForm() {
      this.loading = true;

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.formData.sender = this.getUser.name;
          this.formData.organization_id = this.getOrganization.id;
          jsonAPI
            .post("invite", this.formData)
            .then(() => {
              window.Bus.$emit("flash-message", {
                text: "Users invited",
                type: "success",
              });
              // mark step complete
              this.$store.dispatch("CHECK_ONBOARDING_STEP", this.step_status);

              this.loading = false;
              this.$emit("inviteCreated");
              this.$emit("close");
            })
            .catch((error) => {
              if (error.response) {
                Object.keys(error.response.data.errors).forEach((key) => {
                  this.$validator.errors.add({
                    field: key,
                    msg: error.response.data.errors[key][0],
                  });
                });
                this.loading = false;
                window.Bus.$emit("flash-message", {
                  text: "error main",
                  type: "error",
                });
              }
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>
