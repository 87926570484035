<template>
  <component :is="layout">
    <prompt :active="isModalVisible" @close="isModalVisible = false">
      <h3 slot="header">
        New store
      </h3>
      <div slot="content">
        <NewStoreForm
          v-on:close="isModalVisible = false"
          @newstore="newStore"
        />
      </div>
    </prompt>
    <prompt :active="isStoreModalVisible" @close="isStoreModalVisible = false">
      <h3 slot="header">
        Update store
      </h3>
      <div slot="content">
        <store-form
          :store="storeToEdit"
          :can-change-store="hasPermission('edit-stores')"
          v-on:close="isStoreModalVisible = false"
        />
      </div>
    </prompt>

    <div class="row fadeInUp" role="main">
      <div class="col-12 col-md-3">
        <OrganizationNav />
      </div>
      <div class="col-12 col-md-9">
        <div class="card border-0 shadow-sm mb-6">
          <div class="card-header">
            <h4>Stores ({{ stores.length }})</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div
                v-for="(store, index) in stores"
                :key="index"
                class="col-md-6"
              >
                <StoreCard
                  :store="store"
                  :index="index"
                  @editStore="editStore"
                  @deleteStore="deleteStore"
                />
              </div>
            </div>
          </div>
          <div v-if="hasPermission('create-stores')" class="card-footer">
            <button
              class="btn btn-secondary float-right"
              @click="isModalVisible = true"
            >
              New store
            </button>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import { mapGetters } from "vuex";
import jsonAPI from "@/api";
import Default from "@/layouts/default.vue";
import Permission from "@/utils/permission";
import Prompt from "@/components/UI/Prompt.vue";
import InviteForm from "@/components/Form/InviteForm.vue";
import StoreForm from "@/components/Form/StoreForm.vue";
import NewStoreForm from "@/components/Form/NewStoreForm.vue";
import StoreCard from "@/components/UI/StoreCard.vue";
import OrganizationNav from "@/components/Nav/OrganizationNav.vue";

export default {
  components: {
    Default,
    Prompt,
    InviteForm,
    StoreForm,
    NewStoreForm,
    StoreCard,
    OrganizationNav,
  },
  extends: Permission,
  beforeRouteEnter(to, from, next) {
    jsonAPI.get("store").then((response) => {
      next((vm) => {
        vm.stores = response.data.data;
      });
    });
  },
  data: function() {
    return {
      layout: "Default",
      isModalVisible: false,
      isStoreModalVisible: false,
      loading: false,
      stores: [],
      storeToEdit: {},
    };
  },
  computed: {
    ...mapGetters(["getOrganization"]),
  },
  methods: {
    editStore(id) {
      this.storeToEdit = this.stores.filter((item) => {
        return item.id === id;
      })[0];
      this.isStoreModalVisible = true;
    },
    newStore(store) {
      this.stores.push(store);
      this.stores.sort((a, b) => {
        return a.name > b.name;
      });
    },
    deleteStore(id) {
      this.stores = this.stores.filter((obj) => {
        return obj.id !== id;
      });
    },
  },
};
</script>
